import gql from 'graphql-tag'


const viewResponse = `
  id type name isActive
  count scannerCode
`

export const LIST_VIEW = (templateType) => gql`query LIST_VIEW ($kioskViewType: String!, $q: FilterInput) {
  views: listKioskView${templateType} (kioskViewType: $kioskViewType, q: $q) {
    id type name isActive
  }
}`

export const DETAIL_VIEW = (templateType) => gql`query DETAIL_VIEW ($kioskViewType: String!, $kioskViewId: Int!) {
  view: detailKioskView${templateType} (kioskViewType: $kioskViewType, kioskViewId: $kioskViewId) {${viewResponse}}
}`

export const CREATE_VIEW = (templateType) => gql`mutation CREATE_VIEW ($kioskViewType: String!, $input: KioskView${templateType}Input!) {
  createView: createKioskView${templateType} (kioskViewType: $kioskViewType, input: $input) {${viewResponse}}
}`

export const UPDATE_VIEW = (templateType) => gql`mutation UPDATE_VIEW ($kioskViewType: String!, $kioskViewId: Int!, $input: KioskView${templateType}Input!) {
  updateView: updateKioskView${templateType} (kioskViewType: $kioskViewType, kioskViewId: $kioskViewId, input: $input) {${viewResponse}}
}`

export const DESTROY_VIEW = (templateType) => gql`mutation DESTROY_VIEW ($kioskViewType: String!, $kioskViewId: Int!) {
  destroyView: destroyKioskView${templateType} (kioskViewType: $kioskViewType, kioskViewId: $kioskViewId) {${viewResponse}}
}`
